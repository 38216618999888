.inputUsuario {
    border: 2px solid #000000;
    border-radius: 20px;
    height: 20px;
    width: 100%;
    margin-top: 10px;
    padding: 5px 0 5px 0;
    text-indent: 15px;
}

.inputUsuario::placeholder {
  text-indent: 15px;
}

.inputCodigo {
    background-color: #e2e1e1;
    width: 80px;
}

.selectUsuario {
    border: 2px solid #000;
    border-radius: 20px;
    height: 35px;
    margin-top: 10px;
    padding: 5px 15px;
    width: 100%;
    background-color: #fff;
}

.selectUsuario option {
    border: 2px solid #000;
    border-radius: 20px;
    height: 35px;
    margin-top: 10px;
    padding: 5px 15px;
    width: 100%;
    background-color: #fff;
}

.lblUsuario {
    text-align: left;
    margin-bottom: 2px;
    display: block;
    margin-top: 20px;
}

.lblProfissional {
    text-align: left;
    margin-bottom: 2px;
    display: block;
    margin-top: 20px;
}

.tituloCadastro {
    margin: 25px 0 30px 0;
    text-align: center;
}

.btnSalvarUsuario {
    background-color: #5DACD5;
    color: #fff;
    position: absolute;
    padding: 8px 70px;
    border: none;
    margin-top: 35px;
    font-weight: bold;
}

.centralizaCampos {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
}

.inputGroup {
    display: flex;
    justify-content: space-between;
}

.inputGroupItem {
    width: 48%;
}

.checkboxAdmin {
    margin-top: 20px;
}

.inputUsuario.error{
    border-color: #d32f2f;
}

.selectUsuario.error{
    border-color: #d32f2f;
}