.tabelaProfissionais {
    margin: 0 auto;
    border: #000 solid 1px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th,
  td {
    padding: 10px;
    text-align: left;
  }
  
  th {
    background-color: #D9D9D9;
  }
  
  tbody tr:nth-child(even) {
    background-color: #D9D9D9;
  }
  
  .btnEditar {
    margin-right: 5px;
  }
  
  .btnExcluir {
    margin-left: 5px;
  }
  
  .btnCadastro {
    text-align: left;
    margin-top: 20px;
  }
  
  .btnCadProfissional {
    background-color: #5DACD5;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
  }
  