.tabelaUsuarios {
    margin: 0 auto;
    border: 1px solid #000;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th,
  td {
    padding: 10px;
    text-align: left;
  }
  
  th {
    background-color: #e2e1e1;
  }
  
  tbody tr:nth-child(even) {
    background-color: #f1f1f1;
  }
  
  .btnEditar {
    margin-right: 5px;
  }
  
  .btnExcluir {
    margin-left: 5px;
  }
  
  .btnCadastro {
    text-align: left;
    margin-top: 20px;
  }
  
  .btnCadUsuario {
    background-color: #5DACD5;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
  }
  