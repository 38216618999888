.navbar {
  color: #fff;
  background-color: rgba(93, 172, 213, 0.29);
  border-bottom: 3px solid #000;
  height: 70px;
  overflow: visible;
  display: flex;
  align-items: center;
  padding: 3px 10px;
  position: relative;
}

li {
  list-style: none;
}

.navbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.tamanhoImgNav {
  width: 9em;
  margin-top: 15px;
}

.left p {
  margin: 0;
  font-size: 26px;
  text-align: right;
}

.menus {
  display: flex;
  align-items: center;
  flex-grow: 1;
  text-align: center;
}

.menu {
  background-color: #F1A23A;
  color: #fff;
  margin-left: 10px;
  border-radius: 8px;
  padding: 15px 30px;
  text-align: center;
  font-weight: bold;
}

.menu:hover,
.menu2:hover {
  background-color: #5DACD5;
}

.menu2 {
  background-color: #F1A23A;
  color: #fff;
  margin-left: 10px;
  border-radius: 8px;
  padding: 6px 30px;
  text-align: center;
  font-weight: bold;
}

.logout {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.usuarioLogado {
  margin-right: 5px;
  font-size: 12px;
}

.btnSair {
  padding: 4px 0;
  font-size: 10px;
  background-color: #FD0000;
  font-weight: bold;
  color: #fff;
  border-radius: 10px;
}

.dropdown-menu {
  position: absolute;
  background-color: #F1A23A;
  margin-top: 10px;
  border-radius: 8px;
  padding: 5px 0;
  list-style: none;
}

.dropdown-item {
  color: #fff;
  padding: 10px 20px;
  display: block;
  text-decoration: none;
}

.dropdown-item:hover {
  background-color: #5DACD5;
}

/* Estilos para telas médias (responsivo) */
@media screen and (max-width: 992px) {
  .navbar {
    flex-direction: row;
  }

  .left p {
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
  }

  .tamanhoImgNav {
    width: 7em;
  }

  .menus {
    flex-direction: row;
  }

  .menu,
  .menu2 {
    font-size: 15px;
    font-weight: bold;
    padding: 17px 5px;
  }

  .menu2 {
    padding: 8px 20px;
  }

  .logout {
    flex-direction: column;
  }

  .usuarioLogado {
    margin-right: 3px;
    font-size: 10px;
  }

  .btnSair {
    font-size: 7px;
  }

  .navbar-content {
    position: relative;
  }
}

/* Estilos para telas menores (responsivo) */
@media screen and (max-width: 768px) {
  .menus {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: rgba(93, 172, 213, 0.29);
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0s ease 0.3s;
  }

  .navbar {
    flex-direction: column;
    height: auto;
  }

  .menu2, .menu {
    border-radius: 0;
  }

  .left p {
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
  }

  .tamanhoImgNav {
    width: 7em;
  }

  .menus {
    flex-direction: column;
    margin-bottom: 10px;
  }

  .menu,
  .menu2 {
    font-size: 15px;
    font-weight: bold;
    padding: 17px 5px;
  }

  .menu2 {
    padding: 8px 20px;
  }

  .logout {
    flex-direction: column;
  }

  .usuarioLogado {
    margin-right: 3px;
    font-size: 10px;
  }

  .btnSair {
    font-size: 7px;
  }

  /* Estilos para toogle da navbar em telas menores */
  .navbar-toggle {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 1;
  }

  .menu-icon,
  .menu-icon:before,
  .menu-icon:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: #fff;
    transition: background-color 0.3s ease;
  }

  .menu-icon:before {
    top: -6px;
  }

  .menu-icon:after {
    top: 6px;
  }

  .hidden .menu-icon {
    background-color: transparent;
  }

  .hidden .menu-icon:before {
    top: 0;
    transform: rotate(45deg);
  }

  .hidden .menu-icon:after {
    top: 0;
    transform: rotate(-45deg);
  }

  .hidden ~ .menus {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }
}
