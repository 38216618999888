.tituloLista {
  margin: 25px 0 60px 0;
  text-align: center;
}

.tabelaEspecialidades {
  margin: 0 auto;
  border: #000 solid 1px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: #D9D9D9;
}

tbody tr:nth-child(even) {
  background-color: #D9D9D9;
}

.btnEditar,
.btnExcluir {
  background-color: #5DACD5;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 20px;
}

.btnCadastro {
  text-align: left;
  margin-top: 20px;
}

.btnCadEspecialidades {
  background-color: #5DACD5;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
}