.inputEspecialidade {
    border: 2px solid #000000;
    border-radius: 20px;
    height: 20px;
    width: 100%;
    margin-top: 10px;
    padding: 5px 0 5px 0;
    text-indent: 15px;
}

.inputEspecialidade::placeholder {
  text-indent: 15px;
}

.inputEspecialidade.error {
    border-color: #d32f2f;
}

.inputCodigo {
    background-color: #e2e1e1;
    width: 80px;
}

.lblEspecialidade {
    text-align: left;
    margin-bottom: 2px;
    display: block;
    margin-top: 10px;
}

.tituloCadastro {
    margin: 25px 0 60px 0;
    text-align: center;
}

.btnSalvarEspecialidade {
    background-color: #5DACD5;
    color: #fff;
    position: absolute;
    padding: 8px 70px;
    border: none;
    margin-top: 35px;
    font-weight: bold;
}

.centralizaCampos {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
}

span.spanErro {
    color: #d32f2f;
    font-size: 14px;
    margin-top: 5px;
}